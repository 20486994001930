import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Bernardo Pitanguy';

  // public audio;
  public audioPaused = false;
  public autoPlay = true;
  public isActive = false


  @ViewChild('addBtn', { static: true }) addBtn: ElementRef;

//  ngAfterViewInit() {
//     fromEvent(this.addBtn.nativeElement, 'click')
//       .subscribe((res) => {
//         if(this.audio.paused && this.autoPlay){
//           console.log('Clicked on body', event);
//            this.audio.play();
//            this.audioPaused = false;
//            this.autoPlay = false;
//         }
//       });
//  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if(this.audio.paused && this.autoPlay){
       console.log('Clicked on body', event);
        // this.audio.play();
        // this.audioPaused = false;
        
        this.togglePlay()
        this.autoPlay = false;
        this.isActive = true;
     }
  }


  isPlaying: boolean = false;
  audio: HTMLAudioElement;
  progress: number = 0;
  currentTrackIndex: number = 0;
  playlist: any[] = [
    {
      title: "Corcovado (Quiet Nights Of Quiet Stars)",
      artist: "Stan Getz, João Gilberto, Astrud GIlberto, Antõnio Carlos Jobim",
      albumCover: "assets/Musicas/gets_cover.png",
      audioSrc: "assets/Musicas/Corcovado.mp3"
    },
    {
      title: "Desafinado",
      artist: "Stan Getz, João Gilberto, Astrud GIlberto, Antõnio Carlos Jobim",
      albumCover: "assets/Musicas/gets_cover.png",
      audioSrc: "assets/Musicas/Desafinado.mp3"
    },
    {
      title: "Doralice",
      artist: "Stan Getz, João Gilberto, Astrud GIlberto, Antõnio Carlos Jobim",
      albumCover: "assets/Musicas/gets_cover.png",
      audioSrc: "assets/Musicas/Doralice.mp3"
    },
    {
      title: "O Grande Amor",
      artist: "Stan Getz, João Gilberto, Astrud GIlberto, Antõnio Carlos Jobim",
      albumCover: "assets/Musicas/gets_cover.png",
      audioSrc: "assets/Musicas/O_Grande_Amor.mp3"
    },
    {
      title: "Para Machuchar Meu Coracao",
      artist: "Stan Getz, João Gilberto, Astrud GIlberto, Antõnio Carlos Jobim",
      albumCover: "assets/Musicas/gets_cover.png",
      audioSrc: "assets/Musicas/Para_Machuchar_Meu_Coracao.mp3"
    },
    {
      title: "Só Danço Samba",
      artist: "Stan Getz, João Gilberto, Astrud GIlberto, Antõnio Carlos Jobim",
      albumCover: "assets/Musicas/gets_cover.png",
      audioSrc: "assets/Musicas/So_Danco_Samba.mp3"
    },
    {
      title: "The Girl From Ipanema",
      artist: "Stan Getz, João Gilberto, Astrud GIlberto, Antõnio Carlos Jobim",
      albumCover: "assets/Musicas/gets_cover.png",
      audioSrc: "assets/Musicas/The_Girl_From_Ipanema.mp3"
    },
    {
      title: "Vivo Sonhando",
      artist: "Stan Getz, João Gilberto, Astrud GIlberto, Antõnio Carlos Jobim",
      albumCover: "assets/Musicas/gets_cover.png",
      audioSrc: "assets/Musicas/Vivo_Sonhando.mp3"
    },
    // Add more tracks here
  ];

  get currentTrack() {
    return this.playlist[this.currentTrackIndex];
  }

  ngOnInit() {
    this.audio = new Audio();
    this.audio.src = this.currentTrack.audioSrc;
    this.audio.addEventListener('timeupdate', () => this.updateProgress());
    this.audio.addEventListener('ended', () => this.nextTrack());
  }

  ngOnDestroy() {
    this.audio.pause();
    this.audio.removeEventListener('timeupdate', () => this.updateProgress());
    this.audio.removeEventListener('ended', () => this.nextTrack());
  }

  togglePlay() {
    if (this.isPlaying) {
      this.audio.pause();
    } else {
      this.audio.play();
    }
    this.isPlaying = !this.isPlaying;
  }

  previousTrack() {
    this.currentTrackIndex = (this.currentTrackIndex - 1 + this.playlist.length) % this.playlist.length;
    this.loadTrack();
  }

  nextTrack() {
    this.currentTrackIndex = (this.currentTrackIndex + 1) % this.playlist.length;
    this.loadTrack();
  }

  loadTrack() {
    this.audio.src = this.currentTrack.audioSrc;
    this.audio.load();
    if (this.isPlaying) {
      this.audio.play();
    }
    this.progress = 0;
  }

  updateProgress() {
    const duration = this.audio.duration;
    const currentTime = this.audio.currentTime;
    this.progress = (currentTime / duration) * 100 || 0;
  }
}
